module.exports={
    language: '中文',
    main:{
        home: '首页',
        introduce:'纪念馆概况',
        digitHall:'数字纪念馆',
        culture:'文物',
        search: '寻红·红巡',
        guide:'参观指南',
        copyright:'版权所有：连云港市革命纪念馆',
        ba:'苏ICP备2023013855号'
    }
}