import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue'),
    children:[
      {
        path: 'home',
        name: 'Home',
        component: () => import( '@/views/pages/home.vue'),
        meta: {
          title: '首页',
          keepAlive: true
        }
      },
      {
        path: 'introduce',
        name: 'Introduce',
        component: () => import( '@/views/pages/introduce.vue'),
        meta: {
          title: '纪念馆概况',
          keepAlive: true
        }
      },
      {
        path: 'dynamic',
        name: 'Dynamic',
        component: () => import( '@/views/pages/dynamic.vue'),
        meta: {
          title: '工作动态',
          keepAlive: true
        }
      },
      {
        path: 'relic',
        name: 'Relic',
        component: () => import( '@/views/pages/relic.vue'),
        meta: {
          title: '文物',
          keepAlive: true
        }
      },
      {
        path: 'search',
        name: 'Search',
        component: () => import( '@/views/pages/search.vue'),
        meta: {
          title: '寻红·红巡',
          keepAlive: true
        }
      },
      {
        path: 'guide',
        name: 'Guide',
        component: () => import( '@/views/pages/guide.vue'),
        meta: {
          title: '参观指南',
          keepAlive: true
        }
      },
      {
        path: 'notice',
        name: 'Notice',
        component: () => import( '@/views/pages/notice.vue'),
        meta: {
          title: '公告栏',
          keepAlive: true
        }
      },
      {
        path: 'service',
        name: 'Service',
        component: () => import( '@/views/pages/service.vue'),
        meta: {
          title: '旅游服务',
          keepAlive: true
        }
      },
      {
        path: 'education',
        name: 'Education',
        component: () => import( '@/views/pages/education.vue'),
        meta: {
          title: '主题教育栏目',
          keepAlive: true
        }
      },
      {
        path: 'result',
        name: 'Result',
        component: () => import( '@/views/pages/result.vue'),
        meta: {
          title: '搜索结果',
          keepAlive: true
        }
      },
      {
        path: 'article',
        name: 'Article',
        component: () => import( '@/views/pages/article.vue'),
        meta: {
          title: '文章',
          keepAlive: true
        }
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.afterEach(() => {
  window.scrollTo(0,0);
})
export default router
