module.exports={
    language: '英文',
    main:{
        home: 'Home',
        introduce:'Hall Profile',
        digitHall:'Digital Hall',
        culture:'Cultural Relics',
        search: 'Welfare Fund',
        guide:'Contact Us',
        introduceDesc:'Lianyungang Revolutionary Memorial Hall is a large-scale patriotic education base that the Central Committee and the Provincial Party  Committee attach great importance to. It is a classic red tourism scenic spot integrating many functions, such as propaganda, education, collection, research and tourism, and also a large-scale revolutionary memorial hall at the municipal level in China at present.',
        more:'More',
        copyright:'Copyrights Lianyungang Revolutionary Memorial Hall',
        ba:'Jiangsu ICP Filing No. 2023013855-1'
    }
}