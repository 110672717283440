import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './lib-flexible'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/css/reset.css'
import './assets/css/common.css'


import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import axios from 'axios'
import { createI18n } from 'vue-i18n';
const app = createApp(App)

// 线上
const baseUrl = 'http://www.lygjng.com/prod-api'
axios.defaults.baseURL = baseUrl
app.use(ElementPlus)
app.config.globalProperties.$http = axios
app.config.globalProperties.$baseUrl = baseUrl
const i18n = createI18n ({
    locale:'zh',
    messages:{
      //中文 
      'zh':require('./lang/zh.js'),
      //英文
      'en':require('./lang/en.js'),
      //韩语
      'ko':require('./lang/ko.js'),
      // 日语
      'jp':require('./lang/jp.js'),
    }
})
app.use(store).use(router).use(i18n).use(ElementPlus).mount('#app')
// app.use(store).use(router).use(ElementPlus).mount('#app')

