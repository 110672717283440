module.exports={
    language: '日语',
    main:{
        home: 'トップページ',
        introduce:'記念館概要',
        digitHall:'デジタル記念館',
        culture:'文物巡礼',
        search: '公益基金',
        guide:'お問い合わせ',
        introduceDesc:'連雲港市革命記念館は中国中央、省委員に高度に重視され、全力に製造された大型愛国主義の教育基地であり；宣伝、収集、研究、旅行などの様々の機能を集めた総合的な赤革命聖地の景勝地であり、ここも現在全国の相当な大きな規模の地級・市級の総合類革命記念館である',
        more:'詳細',
        copyright:'著作権所有:連雲港市革命記念館',
        ba:'蘇ICP備2023013855号-1',
    }
}