module.exports={
    language: '韩语',
    main:{
        home: '메인 화면',
        introduce:'기념관 개황',
        digitHall:'디지털 기념관',
        culture:'문물 순례',
        search: '공익기금',
        guide:'연락처',
        introduceDesc:'연운항시 혁명기념관은 중앙, 성위원회가 고도로 중시하고 힘을 기울여 만든 대형 애국주의 교육기지이다. 선교, 수장, 연구, 관광 등 많은 기능을 일체화한 종합적인 홍색관광 경전 관광지이며 현재 전국 규모가 비교적 큰 지시급 종합류 혁명기념관이기도 하다',
        more:'이상',
        copyright:'Copyright:연운항혁명기념관',
        ba:'소ICP비2023013855호-1',
    }
}